<template>
  <div>
    <div class="tableTitle">
      <span class="title"> 采购部审核意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input :rows="4" type="textarea" placeholder="请输入" v-model.trim="postFrom.purchase_advice" :disabled="returnDisabled([1, 2, 3, 4])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307 marginRight20" v-model.trim="postFrom.purchase_sign" :disabled="returnDisabled([1, 2, 3, 4])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"></i-input>
          <RadioGroup v-model="postFrom.purchase_review_status">
            <Radio class="marginRight20" label="1" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2, 3, 4])">同意</Radio>
            <Radio label="0" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2, 3, 4])">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="postFrom.purchase_sign_date" @on-open-change="openChange($event, 'purchase_sign_date')" @on-change="changeTime($event, 'purchase_sign_date')" :disabled="returnDisabled([1, 2, 3, 4])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"></DatePicker>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <span class="title"> 质量管理部门审核意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input :rows="4" type="textarea" placeholder="请输入" v-model.trim="postFrom.quality_advice" :disabled="returnDisabled([1, 2, 8, 15])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307 marginRight20" v-model.trim="postFrom.quality_sign" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3" :disabled="returnDisabled([1, 2, 8, 15])"></i-input>
          <RadioGroup v-model="postFrom.quality_review_status">
            <Radio class="marginRight20" label="1" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2, 8, 15])">同意</Radio>
            <Radio label="0" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2, 8, 15])">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="datetime" placeholder="请选择" class="iviewIptWidth307" @on-open-change="openChange($event, 'quality_sign_date')" :value="postFrom.quality_sign_date" @on-change="changeTime($event, 'quality_sign_date')" :disabled="returnDisabled([1, 2, 8, 15])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"></DatePicker>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <span class="title"> 财务部审核意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input :rows="4" type="textarea" placeholder="请输入" v-model.trim="postFrom.finance_advice" :disabled="returnDisabled([1, 2, 10, 11])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307 marginRight20" v-model.trim="postFrom.finance_sign" :disabled="returnDisabled([1, 2, 10, 11])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"></i-input>
          <RadioGroup v-model="postFrom.finance_review_status">
            <Radio class="marginRight20" label="1" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2, 10, 11])">同意</Radio>
            <Radio label="0" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2, 10, 11])">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="datetime" placeholder="请选择" class="iviewIptWidth307" @on-open-change="openChange($event, 'finance_sign_date')" :value="postFrom.finance_sign_date" @on-change="changeTime($event, 'finance_sign_date')" :disabled="returnDisabled([1, 2, 10, 11])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"></DatePicker>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <span class="title"> 总经理意见 </span>
    </div>
    <div class="fromDiv">
      <div class="fullInput">
        <i-input :rows="4" type="textarea" placeholder="请输入" v-model.trim="postFrom.manager_advice" :disabled="returnDisabled([1, 2])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"> </i-input>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307 marginRight20" v-model.trim="postFrom.manager_sign" :disabled="returnDisabled([1, 2])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3"></i-input>
          <RadioGroup v-model="postFrom.manager_review_status">
            <Radio class="marginRight20" label="1" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2])">同意</Radio>
            <Radio label="0" :disabled="postFrom.check_state == 2 || postFrom.check_state == 3 || returnDisabled([1, 2])">驳回</Radio>
          </RadioGroup>
        </div>
        <FromLabel label="签字日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker :disabled="returnDisabled([1, 2])" :readonly="postFrom.check_state == 2 || postFrom.check_state == 3" @on-open-change="openChange($event, 'manager_sign_date')" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="postFrom.manager_sign_date" @on-change="changeTime($event, 'manager_sign_date')"></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'productApproval',
  components: {
    FromLabel,
  },
  computed: {
    returnDisabled(list) {
      let that = this
      return function (list) {
        let arr = [...list, ...that.roleList]
        if (new Set(arr).size != arr.length) {
          return false
        } else {
          return true
        }
      }
    },
  },
  created() {
    this.roleList = JSON.parse(localStorage.getItem('userRoleList'))
  },
  data() {
    return {
      roleList: [],
      type: '',
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      chooseGroup: '驳回',
      postFrom: {
        purchase_advice: '',
        purchase_sign: '',
        purchase_review_status: '',
        purchase_sign_date: '',
        quality_advice: '',
        quality_sign: '',
        quality_review_status: '',
        quality_sign_date: '',
        finance_advice: '',
        finance_sign: '',
        finance_review_status: '',
        finance_sign_date: '',
        manager_advice: '',
        manager_sign: '',
        manager_review_status: '',
        manager_sign_date: '',
      },
      status: '',
      count: 1,
    }
  },
  mounted() {
    this.status = this.$route.query.status
    this.$root.$on('productInfo', res => {
      let detali = res
      if (this.count == 1) {
        this.postFrom = {
          purchase_advice: detali.purchase_advice,
          purchase_sign: detali.purchase_sign,
          purchase_review_status: detali.purchase_review_status + '',
          purchase_sign_date: detali.purchase_sign_date ? this.$moment(detali.purchase_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss') : '',
          quality_advice: detali.quality_advice,
          quality_sign: detali.quality_sign,
          quality_review_status: detali.quality_review_status + '',
          quality_sign_date: detali.quality_sign_date ? this.$moment(detali.quality_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss') : '',
          finance_advice: detali.finance_advice,
          finance_sign: detali.finance_sign,
          finance_review_status: detali.finance_review_status + '',
          finance_sign_date: detali.finance_sign_date ? this.$moment(detali.finance_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss') : '',
          manager_advice: detali.manager_advice,
          manager_sign: detali.manager_sign,
          manager_review_status: detali.manager_review_status + '',
          manager_sign_date: detali.manager_sign_date ? this.$moment(detali.manager_sign_date * 1000).format('YYYY-MM-DD HH:mm:ss') : '',
          check_state: detali.check_state,
        }
      }
      this.count++
    })
  },
  methods: {
    openChange(e, name) {
      if (!this.postFrom[name]) {
        this.postFrom[name] = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        console.log(this.postFrom[name])
      }
    },
    // 时间改变
    changeTime(e, name) {
      this.postFrom[name] = e
    },
  },
}
</script>

<style scoped lang="less">
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 10px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}
.marginRight20 {
  margin-right: 20px;
}
/deep/ .fullInput {
  width: 100%;
  height: 85px;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #e8eaec;
  .ivu-input-wrapper {
    height: 100% !important;
  }
  textarea.ivu-input {
    height: 100% !important;
  }
}
</style>
